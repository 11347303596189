import { useRouter } from "next/router";

import Error from "@/components/feedback/error";

function PageNotFound() {
  const router = useRouter();

  return (
    <div className="container py-4">
      <Error onClick={() => router.push("/")} />
    </div>
  );
}

export default PageNotFound;
