import Image from "next/image";

import PropTypes from "prop-types";

import PrimaryBtn from "@/components/actions/button/primaryBtn";

function Error({ type, heading, subHeading, btnText, Image, onClick }) {
  return (
    <div className="mx-auto mt-[2.5rem] flex max-w-full flex-col-reverse items-center justify-center px-[1.875rem] md:max-w-3xl md:flex-row md:justify-between lg:mt-[6.25rem] xl:max-w-6xl">
      <div className="flex max-w-[26.5rem] flex-col items-center text-center md:items-baseline md:text-left">
        <small className="text-[1.125rem] text-primary">{type}</small>
        <h1 className="mt-[1rem] text-[1.5rem] font-semibold tracking-wider text-grayDisplay-400 md:text-[2.5rem]">
          {heading}
        </h1>

        <p className="mt-[1.375rem] text-[0.938rem] text-grayDisplay-850 md:text-[1.125rem]">
          {subHeading}
        </p>

        <PrimaryBtn
          btnText={btnText}
          className="mt-[2.5rem] h-[3rem] w-[11.25rem] text-[0.938rem] md:text-[1.25rem]"
          onClick={onClick}
        />
      </div>
      <div className="ml-[3rem] lg:ml-0">{Image}</div>
    </div>
  );
}

export default Error;

Error.defaultProps = {
  type: "Error 404",
  heading: "Somethings gone missing….",
  subHeading: `Opps sorry, the page you are looking for doesn’t exists or could not
  be found.`,
  btnText: "Back to Home",
  Image: (
    <Image
      src="/assets/images/404.png"
      width={561}
      height={431}
      alt="Error 404"
    />
  ),
};

Error.propTypes = {
  type: PropTypes.string,
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  btnText: PropTypes.string,
  Image: PropTypes.node,
  onClick: PropTypes.func,
};
